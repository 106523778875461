import {
  createStylesParam,
  createStylesParams,
  StyleParamType,
  type IStyleParam,
} from '@wix/tpa-settings';
import { AUTHOR_INFO_TYPE_NAME_PICTURE, Layout } from '@wix/communities-blog-client-common';
import { dangerousKeyTransformationOverride } from '@app/common/settings-components/helpers';
import { type GetIStylesParams } from '@app/common/style-params';
import postStyleParams from '@app/components/Post/stylesParams';

const styleParamsForFullPostLayoutObject = {
  'post-textColor': postStyleParams.textParagraphColor,
  'post-pageFont': postStyleParams.textParagraphFont,
  'post-linkHashtagColor': postStyleParams.textLinksAndHashtagsColor,
  'post-quotesColor': postStyleParams.textQuoteColor,
  'post-quotesFont': postStyleParams.textQuoteFont,
  'post-titleColor': postStyleParams.textH1Color,
  'post-titleFont': postStyleParams.textH1Font,
  'post-headerTwoColor': postStyleParams.textH2Color,
  'post-headerTwoFont': postStyleParams.textH2Font,
  'post-headerThreeColor': postStyleParams.textH3Color,
  'post-headerThreeFont': postStyleParams.textH3Font,
  'post-headerFourColor': postStyleParams.textH4Color,
  'post-headerFourFont': postStyleParams.textH4Font,
  'post-headerFiveColor': postStyleParams.textH5Color,
  'post-headerFiveFont': postStyleParams.textH5Font,
  'post-headerSixColor': postStyleParams.textH6Color,
  'post-headerSixFont': postStyleParams.textH6Font,
  'post-cardBackgroundColor': postStyleParams.postBackgroundColor,
  'post-cardBorderWidth': postStyleParams.postBorderWidth,
  'post-cardBorderColor': postStyleParams.postBorderColor,
  'post-ratingUnfilledStarColor': postStyleParams.ratingsStarEmptyColor,
  'post-ratingFilledStarColor': postStyleParams.ratingsStarFilledColor,
  'post-ratingFont': postStyleParams.ratingsTextColor,
  'post-ratingFontColor': postStyleParams.ratingsTextFont,
};

const STYLE_PARAMS_FOR_POST_PAGE_LAYOUT: {
  [key: string]: Omit<IStyleParam, 'name'>;
} = Object.fromEntries(
  Object.entries(styleParamsForFullPostLayoutObject).map(([key, styleParam]) => [
    key,
    createStylesParam(key, styleParam),
  ]),
);

/** @deprecated Legacy, if 1 - blog-isAuthorPictureEnabled:false, if 0 - blog-isAuthorPictureEnabled:true  */
const showLegacyAuthorInfoType = createStylesParam('blog-authorInfoType', {
  type: StyleParamType.Number,
  getDefaultValue: () => AUTHOR_INFO_TYPE_NAME_PICTURE,
});

/** @deprecated Legacy, if 1 - blog-isAuthorPictureEnabled:false, if 0 - blog-isAuthorPictureEnabled:true  */
const showLegacyAuthorInfoTypeMobile = createStylesParam('blog-mobile-authorInfoType', {
  type: StyleParamType.Number,
  getDefaultValue: () => AUTHOR_INFO_TYPE_NAME_PICTURE,
});

const STYLE_PARAMS = {
  // #region Display - Desktop
  showAuthorName: createStylesParam<StyleParamType.Boolean>('blog-isAuthorNameEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showAuthorPicture: createStylesParam<StyleParamType.Boolean>('blog-isAuthorPictureEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue }) => {
      return getStyleParamValue(showLegacyAuthorInfoType) === AUTHOR_INFO_TYPE_NAME_PICTURE;
    },
  }),
  showPostPublishDate: createStylesParam<StyleParamType.Boolean>('blog-isPostPublishDateEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showReadingTime: createStylesParam<StyleParamType.Boolean>('blog-isReadingTimeEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showCategoryLabel: createStylesParam<StyleParamType.Boolean>('blog-isCategoryLabelEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  /** Hidden on Studio Editor */
  showMenu: createStylesParam<StyleParamType.Boolean>('blogMenu-isEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  /** Hidden on Studio Editor */
  showMenuLoginButton: createStylesParam<StyleParamType.Boolean>('widget-isLoginButtonEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isEditorX }) => (isEditorX ? false : true),
  }),
  showMenuCategoryLabels: createStylesParam<StyleParamType.Boolean>(
    'blogMenu-isCategoryLabelEnabled',
    {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    },
  ),
  showMoreOptions: createStylesParam<StyleParamType.Boolean>('blog-isMoreOptionsMenuEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showDescription: createStylesParam<StyleParamType.Boolean>('blog-isPostDescriptionEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPostRating: createStylesParam<StyleParamType.Boolean>('blog-isPostRatingEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showCommentCount: createStylesParam<StyleParamType.Boolean>('blog-isCommentCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showViewCount: createStylesParam<StyleParamType.Boolean>('blog-isViewCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showLikeCount: createStylesParam<StyleParamType.Boolean>('blog-isLikeCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  // #endregion
  // #region Display - Mobile
  isMobileDisplaySettingsEnabled: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isMobileDisplaySettingsEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  showAuthorNameMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isAuthorNameEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),

  showAuthorPictureMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isAuthorPictureEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: ({ getStyleParamValue }) => {
        return getStyleParamValue(showLegacyAuthorInfoTypeMobile) === AUTHOR_INFO_TYPE_NAME_PICTURE;
      },
    },
  ),
  showCategoryLabelMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isCategoryLabelEnabled',
    {
      type: StyleParamType.Boolean,
      getDefaultValue: () => false,
    },
  ),
  showPostPublishDateMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostPublishDateEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),

  showReadingTimeMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isReadingTimeEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  /** Hidden on Studio Editor */
  showMenuMobile: createStylesParam<StyleParamType.Boolean>('blogMenu-mobile-isEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  /** Hidden on Studio Editor */
  showMenuLoginButtonMobile: createStylesParam<StyleParamType.Boolean>(
    'blogMenu-mobile-isLoginButtonEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: ({ isEditorX }) => (isEditorX ? false : true),
    },
  ),
  showMenuCategoryLabelsMobile: createStylesParam<StyleParamType.Boolean>(
    'blogMenu-mobile-isCategoryLabelEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showMoreOptionsMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isMoreOptionsMenuEnabled',
    {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    },
  ),
  showDescriptionMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostDescriptionEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showPostRatingMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostRatingEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showCommentCountMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isCommentCountEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showViewCountMobile: createStylesParam<StyleParamType.Boolean>('blog-mobile-isViewCountEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showLikeCountMobile: createStylesParam<StyleParamType.Boolean>('blog-mobile-isLikeCountEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  // #endregion
  // #region Display - Social sharing - Desktop
  showShare: createStylesParam<StyleParamType.Boolean>('socialSharing-isEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showShareIconFacebook: createStylesParam<StyleParamType.Boolean>('socialSharing-facebook', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showShareIconX: createStylesParam<StyleParamType.Boolean>('socialSharing-twitter', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showShareIconLinkedIn: createStylesParam<StyleParamType.Boolean>('socialSharing-linked-in', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showShareIconLink: createStylesParam<StyleParamType.Boolean>('socialSharing-link', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  // #endregion
  // #region Display - Social sharing - Mobile
  showShareMobile: createStylesParam<StyleParamType.Boolean>('socialSharing-mobile-isEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showShareIconFacebookMobile: createStylesParam<StyleParamType.Boolean>(
    'socialSharing-mobile-facebook',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showShareIconXMobile: createStylesParam<StyleParamType.Boolean>('socialSharing-mobile-twitter', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showShareIconLinkedInMobile: createStylesParam<StyleParamType.Boolean>(
    'socialSharing-mobile-linked-in',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showShareIconLinkMobile: createStylesParam<StyleParamType.Boolean>('socialSharing-mobile-link', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  // #endregion
  // #region Layout - Desktop
  layoutTypeAllPostsFeed: createStylesParam<StyleParamType.Number>('layout-homepage-layoutType', {
    type: StyleParamType.Number,
    getDefaultValue: () => Layout.PgSideBySide,
  }),
  layoutTypeCategoryFeed: createStylesParam<StyleParamType.Number>('layout-category-layoutType', {
    type: StyleParamType.Number,
    getDefaultValue: () => Layout.PgSideBySide,
  }),
  // #region Layout - Mobile
  layoutTypeAllPostsFeedMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-homepage-layoutType',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => Layout.OneColumnMobile,
    },
  ),
  layoutTypeCategoryFeedMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-category-layoutType',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => Layout.OneColumnMobile,
    },
  ),
} as const satisfies {
  [key: string]: Omit<IStyleParam, 'name'>;
};

export type IStylesParams = GetIStylesParams<typeof STYLE_PARAMS>;

export default createStylesParams<IStylesParams>({
  ...STYLE_PARAMS_FOR_POST_PAGE_LAYOUT,
  ...STYLE_PARAMS,
});
